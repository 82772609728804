export function nameInitials(name: string) {
  const initials = name.split(" ").map((word) => word[0]);
  return initials.length === 1 && initials[0]
    ? initials[0].toUpperCase()
    : `${initials[0]?.toUpperCase() ?? ""}${
        initials[initials.length - 1]?.toUpperCase() ?? ""
      }`;
}

const CLOUDINARY_CONFIG = {
  url: "https://res.cloudinary.com/phildl-cloudinary/image/upload/",
  path: "/atelieregon",
};

export const getCloudinaryImg = (img: string, options: string) => {
  return `${CLOUDINARY_CONFIG.url}${options}${CLOUDINARY_CONFIG.path}${img}`;
};
